import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

/* External Components */

/* Routing Provider */
import { TestimonialRoutingProvider } from './testimonial.routes';

import { TestimonialListingComponent } from "./testimonials-listing.component";

@NgModule({
    imports: [
        BrowserModule, FormsModule, HttpModule,
        BrowserAnimationsModule,
        TestimonialRoutingProvider
    ],
    
    // module dependencies
    declarations: [
        TestimonialListingComponent
    ],
    providers: [],
    exports: [TestimonialListingComponent]
})
export class TestimonialModule { }
