import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

/* External Components */

/* Routing Provider */
import { SlideshowItemRoutingProvider } from './slideshow-item.routes';

import { SlideshowItemViewAllComponent } from "./slideshow-item-view-all.component";

@NgModule({
    imports: [
        BrowserModule, FormsModule, HttpModule,
        BrowserAnimationsModule,
        SlideshowItemRoutingProvider
    ],
    
    // module dependencies
    declarations: [
        SlideshowItemViewAllComponent
    ],
    providers: [],
    exports: [SlideshowItemViewAllComponent]
})
export class SlideshowItemModule { }
