import { Component, OnInit } from '@angular/core';

import { DataManager } from 'techlify-ng';
import { RequestErrorService } from "techlify-ng";

import { Award } from 'school-mis-core';
import { AwardDataService } from "school-mis-core";

/**
 * Controller used to view all Awards.
 * 
 * @author Floyd Kissoon
 * @since 20181002
 */
@Component({
    selector: 'award-view-all',
    templateUrl: './award-view-all.html'
})
export class AwardViewAllComponent implements OnInit {
    private awards: Award[];
    private filters: any;

    constructor(private dataManager: DataManager, private errorHandler: RequestErrorService, private dataService: AwardDataService) {
        this.filters = {};
        this.filters.sort_by = "id|DESC";
    }

    ngOnInit() {
        this.loadData();
    }

    loadData() {
        this.dataService.getPublicAwards(this.filters).then(
            res => {
                this.awards = res.items;
            }
        );
    }
}
