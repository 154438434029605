import { Component } from '@angular/core';
import { ConfigService } from 'techlify-ng';

/**
 * Controller that handels the home page
 * 
 * @author Floyd Kissoon
 * @since 20180611
 */
@Component({
    selector: "home-page",
    templateUrl: "./home.html"
})
export class HomeComponent {
    constructor(private config: ConfigService) { }
}