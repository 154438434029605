import { Component, OnInit, Input, } from "@angular/core";

import { SlideshowItemDataService } from "school-mis-core";
import { SlideshowItem } from "school-mis-core";

@Component({
    selector: "slideshow-item-view-all",
    templateUrl: "./slideshow-item-view-all.html"
})
export class SlideshowItemViewAllComponent implements OnInit {
    private slideshowItems: SlideshowItem[];
    private filters: any;

    constructor(private dataService: SlideshowItemDataService) {
        this.slideshowItems = new Array<SlideshowItem>();
        this.filters = {};
        this.filters.sort_by = "id|ASC";
    }

    ngOnInit() {
        this.loadData();
    }
    private loadData() {
        this.dataService.getSlideshowItem().then(
            res => {
                this.slideshowItems = res.items;
            }
        );
    }

}