import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

import { AppComponent } from './app.component';

/* Routing Provider */
import { RoutingProvider } from './app.routes';

import { TechlifyServicesModule } from "techlify-ng";
import { TechlifyConfig } from "techlify-ng";

declare var require: any;
var configInstance = new TechlifyConfig(require("./config/config-prod.json"));
import { HomeModule } from "./modules/home/home.module";
import { SubjectModule } from './modules/subject/subject.module';
import { TeacherModule } from './modules/teacher/teacher.module';
import { NewsArticleModule } from './modules/news-article/news-article.module';
import { CxcExamModule } from './modules/cxc-exam/cxc-exam.module';
import { TestimonialModule } from './modules/testimonial/testimonial.module';
import { SlideshowItemModule } from "./modules/slideshow-item/slideshow-item.module";
import { FacilityModule } from "./modules/facility/facility.module";
import { AwardModule } from "./modules/award/award.module";

import { SubjectCoreModule } from "school-mis-core";
import { TeacherCoreModule } from "school-mis-core";
import { NewsArticleCoreModule } from "school-mis-core";
import { CxcExamCoreModule } from "school-mis-core";
import { TestimonialCoreModule } from "school-mis-core";
import { UserCoreModule } from "school-mis-core";
import { SlideshowItemCoreModule } from "school-mis-core";
import { FacilityCoreModule } from "school-mis-core";
import { AwardCoreModule } from "school-mis-core";

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule, BrowserAnimationsModule, FormsModule, HttpModule,
    RoutingProvider,
    TechlifyServicesModule,
    SubjectCoreModule, TeacherCoreModule,
    CxcExamCoreModule, NewsArticleCoreModule, TestimonialCoreModule,
    UserCoreModule, SlideshowItemCoreModule, FacilityCoreModule,
    AwardCoreModule,
    HomeModule, TestimonialModule, TeacherModule, SubjectModule,
    NewsArticleModule, CxcExamModule, SlideshowItemModule, FacilityModule,
    AwardModule
  ],
  providers: [{ provide: TechlifyConfig, useValue: configInstance }],
  bootstrap: [AppComponent]
})
export class AppModule { }
