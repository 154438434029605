import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

/* External Components */

/* Routing Provider */
import { CxcExamRoutingProvider } from './cxc-exam.routes';

import { CxcExamStudentPreformanceListingComponent } from "./cxc-exam-student-performance-listing.component";

@NgModule({
    imports: [
        BrowserModule, FormsModule, HttpModule,
        BrowserAnimationsModule,
        CxcExamRoutingProvider
    ],
    
    // module dependencies
    declarations: [
        CxcExamStudentPreformanceListingComponent
    ],
    providers: [],
    exports: [CxcExamStudentPreformanceListingComponent]
})
export class CxcExamModule { }
