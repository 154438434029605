import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

/* External Components */

/* Routing Provider */
import { HomeRoutingProvider } from './home.routes';

import { HomeComponent } from "./home.component";
import { SubjectModule } from '../subject/subject.module';
import { TeacherModule } from '../teacher/teacher.module';
import { NewsArticleModule } from '../news-article/news-article.module';
import { CxcExamModule } from '../cxc-exam/cxc-exam.module';
import { TestimonialModule } from '../testimonial/testimonial.module';
import { SlideshowItemModule } from '../slideshow-item/slideshow-item.module';

@NgModule({
    imports: [
        BrowserModule, FormsModule, HttpModule,
        BrowserAnimationsModule,
        HomeRoutingProvider, CxcExamModule,
        SubjectModule, TeacherModule, NewsArticleModule,
        TestimonialModule, SlideshowItemModule
    ],

    // module dependencies
    declarations: [
        HomeComponent
    ],
    providers: [],
    exports: []
})
export class HomeModule { }
