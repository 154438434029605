import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

/* External Components */

/* Routing Provider */
import { TeacherRoutingProvider } from './teacher.routes';

import { TeacherListingComponent } from "./teachers-listing.component";

@NgModule({
    imports: [
        BrowserModule, FormsModule, HttpModule,
        BrowserAnimationsModule,
        TeacherRoutingProvider
    ],
    
    // module dependencies
    declarations: [
        TeacherListingComponent
    ],
    providers: [],
    exports: [TeacherListingComponent]
})
export class TeacherModule { }
