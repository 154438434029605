import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

/* External Components */

/* Routing Provider */
import { AwardRoutingProvider } from './award.routes';

import { AwardViewAllComponent } from "./award-view-all.component";

@NgModule({
    imports: [
        BrowserModule, FormsModule, HttpModule,
        BrowserAnimationsModule,
        AwardRoutingProvider
    ],

    // module dependencies
    declarations: [
        AwardViewAllComponent
    ],
    providers: [],
    exports: []
})
export class AwardModule { }
