import { Component, OnInit, Input, } from "@angular/core";

import { CxcExamDataService } from "school-mis-core";

@Component({
    selector: "cxc-exam-student-performance-listing",
    templateUrl: "./cxc-exam-student-performance-listing.html"
})
export class CxcExamStudentPreformanceListingComponent implements OnInit {
    private data: any;
    private filters: any;

    constructor(private dataService: CxcExamDataService) {
        this.filters = {};
        this.filters.num_items = 10
    }

    ngOnInit() {
        this.loadData();
    }
    private loadData() {
        this.dataService.getStudentCxcPerformance(this.filters).then(
            res => {
                this.data = res.items;
            }
        );
    }

}