import { Component, OnInit, Input, } from "@angular/core";

import { TeacherDataService } from "school-mis-core";
import { Teacher } from "school-mis-core";

@Component({
    selector: "teachers-listing",
    templateUrl: "./teachers-listing.html"
})
export class TeacherListingComponent implements OnInit {
    private teachers: Teacher[];
    private filters: any;

    constructor(private dataService: TeacherDataService) {
        this.teachers = new Array<Teacher>();
        this.filters = {};
        this.filters.sort_by = "id|ASC";
        this.filters.num_items = "2";
    }

    ngOnInit() {
        this.loadData();
    }
    private loadData() {
        this.dataService.getTeachers(this.filters).then(
            res => {
                this.teachers = res.items;
            }
        );
    }

}