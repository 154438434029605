import { Component, OnInit, Input, } from "@angular/core";

import { TestimonialDataService } from "school-mis-core";
import { Testimonial } from "school-mis-core";

@Component({
    selector: "testimonials-listing",
    templateUrl: "./testimonials-listing.html"
})
export class TestimonialListingComponent implements OnInit {
    private testimonials: Testimonial[];
    private filters: any;

    constructor(private dataService: TestimonialDataService) {
        this.testimonials = new Array<Testimonial>();
        this.filters = {};
        this.filters.sort_by = "id|ASC";
        this.filters.num_items = "2";
    }

    ngOnInit() {
        this.loadData();
    }
    private loadData() {
        this.dataService.getTestimonials(this.filters).then(
            res => {
                this.testimonials = res.items;
            }
        );
    }

}