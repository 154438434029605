import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

/* External Components */

/* Routing Provider */
import { FacilityRoutingProvider } from './facility.routes';

import { FacilityViewAllComponent } from "./facility-view-all.component";

@NgModule({
    imports: [
        BrowserModule, FormsModule, HttpModule,
        BrowserAnimationsModule,
        FacilityRoutingProvider
    ],
    
    // module dependencies
    declarations: [
        FacilityViewAllComponent
    ],
    providers: [],
    exports: []
})
export class FacilityModule { }
