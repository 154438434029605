import { Component, OnInit, Input, } from "@angular/core";

import { SubjectDataService } from "school-mis-core";
import { Subject } from "school-mis-core";

@Component({
    selector: "subjects-lising",
    templateUrl: "./subjects-listing.html"
})
export class SubjectListingComponent implements OnInit {
    private subjects: Subject[];


    constructor(private dataService: SubjectDataService) {
        this.subjects = new Array<Subject>();
    }

    ngOnInit() {
        this.loadData();
    }
    private loadData() {
        this.dataService.getSubjects().then(
            res => {
                this.subjects = res.items;
            }
        );
    }

}