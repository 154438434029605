import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

/* External Components */

/* Routing Provider */
import { NewsArticleRoutingProvider } from './news-article.routes';

import { NewsArticleListingComponent } from "./news-article-listing.component";
import { NewsArticleViewAllComponent } from "./news-article-view-all.component";

@NgModule({
    imports: [
        BrowserModule, FormsModule, HttpModule,
        BrowserAnimationsModule,
        NewsArticleRoutingProvider
    ],
    
    // module dependencies
    declarations: [
        NewsArticleListingComponent, NewsArticleViewAllComponent
    ],
    providers: [],
    exports: [NewsArticleListingComponent]
})
export class NewsArticleModule { }
