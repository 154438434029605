import { Component, OnInit, Input, } from "@angular/core";

import { NewsArticleDataService } from "school-mis-core";
import { NewsArticle } from "school-mis-core";

@Component({
    selector: "news-article-listing",
    templateUrl: "./news-article-listing.html"
})
export class NewsArticleListingComponent implements OnInit {
    private newsArticles: NewsArticle[];
    private filters: any;

    constructor(private dataService: NewsArticleDataService) {
        this.newsArticles = new Array<NewsArticle>();
        this.filters = {};
        this.filters.num_items = "3";
        this.filters.sort_by = "id|DESC"
    }

    ngOnInit() {
        this.loadData();
    }
    private loadData() {
        this.dataService.getNewsArticles(this.filters).then(
            res => {
                this.newsArticles = res.items;
            }
        );
    }

}