import { Component, OnInit } from '@angular/core';

import { DataManager } from 'techlify-ng';
import { RequestErrorService } from "techlify-ng";

import { NewsArticle } from 'school-mis-core';

/**
 * Controller used to view all News Article.
 * 
 * @author Floyd Kissoon
 * @since 20181002
 */
@Component({
    selector: 'news-article-view-all',
    templateUrl: './news-article-view-all.html'
})
export class NewsArticleViewAllComponent implements OnInit {
    private newsArticles: NewsArticle[];
    private filters: any;

    constructor(private dataManager: DataManager, private errorHandler: RequestErrorService) {
        this.filters = {};
        this.filters.sort_by = "id|DESC";
        this.filters.headline = "";
    }

    ngOnInit() {
        this.loadData();
    }

    loadData() {
        this.dataManager.GET("api/public/news-articles", this.filters).then(
            res => {
                this.newsArticles = res.items;
            },
            error => {
                this.errorHandler.handleError(error);
            }
        );
    }

}
